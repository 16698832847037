import { Box, List, ListItem, ListItemProps, ListItemText, SxProps, Typography } from "@mui/material";
import { ReactElement, ReactNode, useState } from "react";
import colors from "../../colors";
import { Controller, useForm } from "react-hook-form";
import { CabButton } from "@CabComponents/CabButton";
import { CabTextInput } from "@CabComponents/CabTextInput";
import { CabDropdown } from "@CabComponents/CabDropdown";
import { CabToggleChip } from "@CabComponents/CabToggleChip";
import { CabAutocomplete } from "@CabComponents/CabAutocomplete";

interface WidgetProps {
  header?: string;
  action?: ReactNode;
  children: ReactNode;
  sx?: SxProps;
}

export const Widget = ({ header, action, children, sx }: WidgetProps): ReactElement => (
  <Box
    border={`1px solid ${colors.black400}`} borderRadius={2}
    padding={1} paddingTop={0} paddingBottom={0}
    width='100%' overflow="hidden" sx={sx}
  >
    <Box display="flex" justifyContent="space-between">
      {header && (
        <Typography variant="h2" fontSize={18} paddingTop={2} paddingLeft={1} paddingBottom={2}>
          {header}
        </Typography>
      )}
      {action}
    </Box>
    {children}
  </Box>
);

export const WidgetList = ({ children }: { children: ReactNode }) => (
  <List disablePadding sx={{ paddingLeft: 1, paddingRight: 1 }}>
    {children}
  </List>
);

export const WidgetListItem = ({ onClick, secondaryAction, children }: {
  onClick?: () => void; secondaryAction?: ListItemProps['secondaryAction'];
  children: ReactNode;
}) => (
  <ListItem divider disableGutters secondaryAction={secondaryAction}>
    {children}
  </ListItem>
);

export const BasicInfoListItem = ({ 
  title, text, color, inputType, inputValue, selectOptions, onSave, onInputChange, placeholder, noOptionsText, onClick
}: {
  title: string, text?: string | null, inputType: 'text'|'select'|'multiline'|'autocomplete', 
  inputValue: string | number | number[] | null, onSave: (val: string | number | number[] | null) => void, 
  color?: string; selectOptions?: { value: string|number, label: string, color?: string }[], 
  onInputChange?: (val: string) => void; placeholder?: string; noOptionsText?: string;
  onClick?: () => void;
}) => {
  const [editing, setEditing] = useState(false);
  const { control, handleSubmit, reset } = useForm({ values: { field: inputValue } });

  const handleSave = async () => {
    await handleSubmit((formValues) => onSave(formValues.field))();
    setEditing(false);
  };

  const handleCancel = () => {
    reset();
    setEditing(false);
  };

  return (
    <WidgetListItem secondaryAction={
      editing ? (
        <Box display="flex" flexDirection="column" gap={1}>
          <CabButton
            buttonType="primary"
            sx={{ paddingTop: 0.25, paddingBottom: 0.25, width: 15 }}
            onClick={handleSave}
          >
            Save
          </CabButton>
          <CabButton
            buttonType="tertiary"
            sx={{ paddingTop: 0.25, paddingBottom: 0.25, width: 15 }}
            onClick={handleCancel}
          >
            Cancel
          </CabButton>
        </Box>
      ) : (
        <CabButton
          buttonType="text"
          sx={{ textDecoration: 'underline', padding: 0, minWidth: 10 }}
          onClick={() => setEditing(true)}
        >
          Edit
        </CabButton>
      )
    }>
      <ListItemText
        primary={title}
        secondary={editing ? (
          <Controller name="field" control={control} render={({ field: { ref, ...field }}) => (
            inputType === 'text' ? (
              <CabTextInput
                inputRef={ref}
                {...field}
                fullWidth
              />
            ) : inputType === 'multiline' ? (
              <CabTextInput
                inputRef={ref}
                {...field}
                fullWidth
                multiline
                minRows={2}
                maxRows={2}
              />
            ) : inputType === 'autocomplete' ? (
              <CabAutocomplete<number, never>
                {...field}
                value={Number(field.value)}
                options={selectOptions?.map(option => ({
                  value: Number(option.value),
                  label: option.label
                })) || []}
                noOptionsText={noOptionsText}
                placeholder={placeholder}
                onInputChange={onInputChange ? (v) => onInputChange(v) : undefined}
                sx={{ width: '100%' }}
              />
            ) : (
              <CabDropdown
                {...field}
                value={field.value || ''}
                options={selectOptions?.map(option => ({
                  value: option.value,
                  label: option.color ? <CabToggleChip chipColor={option.color} label={option.label} selected />
                    : option.label
                })) || []}
                sx={{ width: '100%' }}
              />
            )
          )} />
        ) : (
          color ? <CabToggleChip chipColor={color} label={text} selected />
            : text || <Box sx={{ height: 30 }} component="span">&nbsp;</Box>
        )}
        primaryTypographyProps={{ fontSize: 13, fontWeight: 'bolder', color: colors.black700 }}
        secondaryTypographyProps={editing
          ? { component: Box, width: '90%' }
          : {
            fontWeight: 'bold', fontSize: 16, color: colors.black900, onClick,
            sx: { cursor: onClick ? 'pointer' : undefined }
          }
        }
      />
    </WidgetListItem>
  );
};